.link-underline-grow {
  display: inline-block;
  text-decoration: none;
  &:after {
    content:"";
    display: block;
    width:100%;
    height:2px;
    background-color: #000;
    @include transition_all();
    transform-origin: top center;
  }
  &:hover {
    &:after {
      transform: scaleY(2);
    }
  }
}
.link-underline-leftright {
  cursor:pointer;
  $transTime:250ms;
  $transEaseInOut:all $transTime ease-in-out;
  $transEaseIn:all $transTime ease-in;
  $transEaseOut:all $transTime ease-out;
  $transOff:all 0ms ease-in-out;
  color:#fff;
  transition: $transEaseInOut;
  display: inline-block;
  text-decoration: none;
  overflow: hidden;
  padding-bottom: 3px;
  &:before,
  &:after {
    content:"";
    position: absolute;
    @include calc(top,'100% - 4px');
    @include calc(left,'-100%');
    display: block;
    width:100%;
    height:1px;
    background-color: #fff;
    transform-origin: top center;
  }
  &:before {
    transition: $transOff;
  }
  &:after {
    @include calc(left,'100%');
    transition: $transEaseOut;
  }
  &.active {
    //color:$color-cyan;
    &:before {
      @include calc(left,'-100% + 20px');
    }
    &:after {
      @include calc(left,'-100% + 20px');
    }
  }
  &:hover {
    //color:$color-cyan;
    &:before {
      left:0;
      transition: $transEaseIn;
    }
    &:after {
      transition: $transEaseOut;
      transition-delay: $transTime;
      left:0;
    }
  }
  &.small {
    &:before,
    &:after {
      height:1px;
    }
  }
}
.link-underline-leftright-dark {
  @extend .link-underline-leftright;
  &:before,
  &:after {
    background-color: $color-red;
  }
}
.link-underline-leftright-red {
  @extend .link-underline-leftright;
  &:before,
  &:after {
    background-color: $color-red;
  }
}
.link-underline-leftright-white {
  @extend .link-underline-leftright;
  &:before,
  &:after {
    background-color: #fff;
  }
}

.miniButton {
  color:#000;
  @include font-montserrat-bold();
  font-size:12px;
  text-transform: uppercase;
  background-color: #ccc;
  border:1px solid #000;
  display: inline-block;
  margin-top:5px;
  padding:5px 5px 2px;
  @include transition-all();
}
button.only-mini {
  background-color: transparent;
  border: none;
  display: inline-block;
  .miniButton {
    cursor: pointer;
    margin-top:0;
    &:hover {
      background-color: #fff;
    }
  }
}



//elipse
.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.link-underline-grow {
  color:$color-red;
  @include font-montserrat-bold();
  font-size:18px;
  line-height: 22px;
  text-transform: uppercase;
  @media only screen and (max-width: $breakpoint-mobile-nav) {
    font-size:15px;
    line-height: 15px;
  }
}

.generic-image-hover {
  cursor: pointer;
  opacity:1;
  @include transition-opacity();
  &:hover {
    opacity:0.85;
  }
}
.scrim {
  position: fixed;
  top:0;
  left:0;
  width: 100%;
  height: 100%;
  background: rgb(26,98,137);
  background: linear-gradient(180deg, rgba(26,98,137,1) 0%, rgba(7,64,96,1) 68%, rgba(0,55,85,1) 100%);
}


.mini-nav-action {
  .mini-nav-action2 {
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    * {
      @include font-montserrat-bold();
      font-size: 16px;
      color:#fff;
    }
  }
  a {
    svg {
      margin-left: 5px;
      width:12px;
      path {
        fill: #fff;
      }
      @include transition-all();
    }
    &:hover {
      svg {
        transform: translateX(5px);
      }
    }
  }
  &.back {
    a {
      svg {
        margin-right: 5px;
        margin-left: 0;
      }
      &:hover {
        svg {
          transform: translateX(-5px);
        }
      }
    }
  }
}

ul.sidenav-links {
  list-style: none;
  overflow: hidden;
  width:340px;
  li {
    display: flex;
    @include font-montserrat-bold();
    font-size: 20px;
    @include transition-all();
    transform: translateX(-30px);
    margin:20px 0;
    a {
      @include transition-all();
      text-transform: uppercase;
      color: #fff;
      opacity: 0.5;
    }
    svg {
      width:14px;
      @include transition-all();
      opacity: 0.5;
      margin-right: 18px;
      path {
        fill:#fff;

      }
    }
    &.active,
    &:hover {
      transform: translateX(0);
      a, svg {
        opacity: 1;
      }
    }

  }
}


.global-close {
  width:40px;
  height: 40px;
  display: block;
  cursor: pointer;
  &:before, &:after {
    content:"";
    width:40px;
    height: 5px;
    border-radius: 7px;
    background-color: #fff;
    position: absolute;
    top:16px;
    left:0;
    @include transition-all();
  }
  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }
  &:hover {
    &:before, &:after {
      transform: rotate(0deg);
      background-color:$color-red;
    }
  }
  &.smaller {
    width:25px;
    height:25px;
    &:before, &:after {
      width:25px;
      height: 2px;
      border-radius: 3px;
    }
  }
}


.below-header {
  padding-top: $headerTall;
  @media only screen and (max-width: $breakpoint-mobile-nav) {
    //padding-top: $headerSmall;
    padding-top: 0;
  }
}



.field-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
  .field-row-element {
    width:45%;
    display: flex;
    align-items: center;
    .lbl {
      color:#fff;
      @include font-montserrat-bold();
      margin-right: 15px;
    }
    input {
      padding:10px 20px;
      @include calc(width,'100% - 40px');
      color:#fff;
      @include font-montserrat-regular();
      font-size: 18px;
      background-color: $color-teal-dk2;
      border: 1px solid $color-teal;
      @include set-input-placeholder-color(#fff);
      &.error {
        border: 1px solid $color-error;
      }
    }
    select {
      -webkit-appearance:none;
      cursor: pointer;
      padding:10px 30px 10px 20px;
      width:100%;
      color:#fff;
      @include font-montserrat-regular();
      background-color: $color-teal-dk2;
      border: 1px solid $color-teal;
      font-size: 18px;
      border-radius: 0;
      background-image: url(../images/global/icon-angle-down.svg);
      background-repeat: no-repeat;
      background-position: 95% 50%;
      background-size: 14px 14px;
      &.error {
        border: 1px solid $color-error;
      }
    }
  }
}



.dropdown {
  $padding: 10px 10px;
  position: relative;
  .dropdown-inner {
    position: relative;
    min-width: 240px;
    @media only screen and (max-width: 1250px) {
      min-width: 200px;
    }
    * {
      color:$color-gray;
      @include font-montserrat-semibold();
      font-size:14px;
      @media only screen and (max-width: $breakpoint-small) {
        font-size:12px;
      }
    }
    .gtranslate_wrapper {
      a {
        display:block;
        width: calc(100% - 20px);
        padding:5px 10px;
      }
    }
    .btn {
      color:#fff!important;
    }
    .selected {
      padding:$padding;
      position: relative;
      cursor: pointer;
      display: inline-flex;
      align-items: center;
      .globe svg {
        width:16px;
        height: auto;
        margin-right: 10px;
        path {
          fill:$color-gray;
        }
      }
      .triangle {
        svg {
          @include transition-all();
          width:12px;
          height: auto;
          margin-left: 10px;
          path {
            fill:$color-gray;
          }
        }
      }

    }
  }

  .items {
    display: none;
    padding:0;
    @include transition-all();
    position: absolute;
    top:39px;//was 42px
    right:0;
    width:100%;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    .lbl {
      display: inline-block;
      @include transition-all();
    }
    .icon {
      width:15px;
      height: auto;
      display: inline-block;
      margin-right: 10px;
      img, svg {
        width: 100%;
        @include transition-all();
      }
    }
  }

  &.open {
    z-index: 100;
    .dropdown-inner {
      background-color: #EFEFEF;

      .triangle {
        svg {
          transform: rotate(-90deg);
        }
      }
    }
    .items {
      background-color: #EFEFEF;
      display: block;
      ul, li {
        list-style: none;
      }
      li {
        a {
          width: calc(100% - 20px);
          padding:10px 10px;
          display: flex;
          @include transition-all();
          svg {
            path {
              @include transition-all();
            }
          }
          &:hover {
            // background-color: #000;
            * {
              color: $color-pink!important;
            }
            color: $color-pink!important;
            svg {
              path {
                fill: $color-pink !important;
              }
            }
          }
        }
        &.li-level1-hook  {
          width: calc(100% - 20px);
          padding:10px 10px;
        }
      }

      ul ul {
        margin: 10px 0 0 0;
      }

      .li-level1-hook {
        .ul-level2 {
          display: none;
          position: absolute;
          top:0;
          left:90%;
          background-color: #EFEFEF;
          width: 100%;
          border-radius:15px;
          z-index: 2;
        }
        &:hover {
          .ul-level2 {
            display: block;
          }
        }
      }
    }
  }

  &.wide-header-right {
    .items {
      width:50vw;
      padding:10px;
      ul {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        //grid-template-rows: repeat(15, 25px); // needs to be dynamic set in the twig
        grid-auto-flow: column;
        // column-gap: 20px;
        // row-gap: 20px;
        @media only screen and (max-width: $breakpoint-small) {
          grid-template-columns: repeat(1, 1fr);
        }
      }
    }
  }

  &.search {
    .items {
      width:350px;
      padding:10px;
    }
  }

}
.dropdown-search {
  width: 100%;
  .tabs {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .tab {
      @include transition-all();
      &.active {
        color:$color-pink;
      }
      margin-right: 10px;
      padding-right: 10px;
      cursor: pointer;
      border-right: 1px solid #B5B5B5;
      &:last-child {
        border-right: none;
      }
    }
  }
  .fields-button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    .fields {
      width: 100%;
      .field {
        display: none;
        @include transition-all();
        &.active {
          display: block;
        }
        input {
          width: calc(100% - 20px);
        }

        &.field-location {
          input {
            padding-right:25px;
            width: calc(100% - 35px);
          }
          .geo-icon {
            position: absolute;
            top:7px;
            right:6px;
            z-index: 2;
            svg {
              width:14px;
              height: 14px;
              cursor: pointer;
              path {
                fill: #797979;
                @include transition-all();
              }
            }
            &:hover {
              svg {
                path {
                  fill: $color-pink;
                }
              }
            }
          }//geo-icon

          #geo-icon-search-geo-icon {
            top:3px;
          }
        }
      }
    }
    .button {
      width:110px;
      margin-left: 20px;
    }
  }
}




.social-icons {
  display: inline-flex;
  align-items: center;
  height: 100%;
  .icon {
    margin:0 8px;
    &:first-child {
      margin-left: 0;
    }
    svg {
      height:18px;
      width: 18px;
      position: relative;
      top:2px;
      path {
        fill:$color-gray;
        @include transition-all();
      }
    }
    &:hover {
      svg {
        path {
          fill:#fff;
        }
      }
    }
  }

  &.red {
    .icon {
      svg {
        path {
          fill:$color-pink;
        }
      }
      &:hover {
        svg {
          path {
            fill:$color-red;
          }
        }
      }
    }
  }
  &.white {
    .icon {
      svg {
        path {
          fill:#fff;
        }
      }
      &:hover {
        svg {
          path {
            fill:$color-pink;
          }
        }
      }
    }
  }
}


input {
  border:1px solid #B5B5B5;
  padding:5px 10px;
  @include set-input-placeholder-color(rgba(121,121,121,0.8));
  color: $color-gray;
}

.btn {
  border: none;
  background-color: $color-pink;
  @include font-montserrat-semibold();
  font-size: 14px;
  line-height: 1em;
  color:#fff;
  text-transform: uppercase;
  padding:20px 30px; //fat padding
  border-radius:30px;
  &.condensed-padding {
    border-radius:20px;
    padding:10px 20px;
  }

  cursor: pointer;
  @include transition-all();
  &:hover {
    background-color: $color-red;
  }
  &.inverse {
    background-color: $color-red;
    &:hover {
      background-color: $color-pink;
    }
  }
  &.white-hover {
    &:hover {
      background-color: #fff;
      color:$color-pink;
    }
  }

  &.dark {
    background-color: #292727;
    border: 1px solid #292727;
    color: #707070;
    &:hover,
    &.active {
      background-color: #000;
      color:#fff;
      border: 1px solid #fff;
    }
  }

  &.white {
    background-color: #fff;
    border: 1px solid #fff;
    color: #707070;
    &:hover,
    &.active {
      background-color: $color-red;
      color:#fff;
      border: 1px solid #fff;
    }
  }

  &.square {
    border-radius: 0;
    padding-top:15px;
    padding-bottom:15px;
    text-transform: unset;
  }

}


.swiper-button-next, .swiper-button-prev {
  width:52px;
  height: 52px;
  border-radius: 50%;
  background-color: #000;
  border:1px solid #3A3A3A;
  @include transition-all();
  svg {
    width:15px;
    height: auto;
    @include transition-all();
    path {
      fill:#fff;
    }
  }
  &:after {
    display: none;
  }
  &:hover {
    //width:62px;
    //height: 62px;
    //transform: translateX(-5px) translateY(-5px);
    transform: scale(1.2);
  }

}
.swiper-button-next {
  right:-65px;
  &:hover {
    border:1px solid #fff;
    svg {
      transform: scale(0.85);
      //transform: translateX(2px) scaleX(1.1);
    }
  }
}
.swiper-button-prev {
  left:-65px;
  &:hover {
    border:1px solid #fff;
    svg {
      transform: scale(0.85);
      //transform: translateX(-2px) scaleX(1.1);
    }
  }
}

.icon-copy {
  display: flex;
  margin: 5px 0;

  .icon {
    @include widthMaxMin(12px);
    margin-right: 15px;
    svg {
      width: 100%;
      path {
        //fill$color-gray;
        fill:$color-dark;
      }
    }
  }
  .copy {
    * {
      //color$color-gray;
      fill:$color-dark;
      font-size: 16px;
      line-height: 1.2em;
    }
    .bold {
      @include font-montserrat-semibold();
    }
    .regular {
      @include font-montserrat-medium();
    }
  }
  &.dark {
    .icon {
      svg {
        path {
          fill:$color-dark;
        }
      }
    }
    .copy {
      * {
        color: $color-dark;
      }
    }
  }
  &.all-gray {
    * {
      font-size: 14px;
    }
    .icon {
      svg {
        path {
          fill:$color-gray!important;
        }
      }
    }
    .copy {
      * {
        color: $color-gray!important;
      }
    }
  }
}

.line {
  border-top: 1px solid #B5B5B5;
  width: 100%;
  @include master-margin-tb();
}


.expandables {
  margin-bottom: 40px;
  .row {
    .top {
      display: flex;
      align-items: center;
      cursor: pointer;
      .rowl {
        width:100%;
        * {
          @include font-montserrat-bold();
        }
      }
      .rowr {
        @include widthMaxMin(16px);
        margin-left: 10px;
        top:1px;
        position: relative;
        svg {
          width:100%;
          @include transition-all();
          transform: rotate(-90deg);
        }
      }
    }
    .bottom {
      height: 0;
      opacity: 0;
      overflow: hidden;
      @include transition-all();
      max-height: 100000px;
    }
    .line {
      margin-top: 20px;
      margin-bottom: 20px;
    }
    &.open {
      .top {
        .rowr {
          svg {
            transform: rotate(0deg);
          }
        }
      }
      .bottom {
        opacity: 1;
        .bottom2 {
        }
      }
    }
    &:last-child {
      .line {
        display: none;
      }
    }
  }//row
}

.tour-linkout-row {
  border-top: 1px solid #E0E0E0;
  padding-bottom: 5px;
  padding-top: 5px;
  margin-bottom: 0px;
  display: flex;
  .col {
    padding:10px 10px 10px 0;
    .l1 {
      @include font-montserrat-medium();
    }
  }
  .col1 {
    width:45%;
  }
  .col2 {
    width:35%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .col3 {
    width:20%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  @media only screen and (max-width: $breakpoint-small-medium) {
    display: block;
    margin-bottom: 10px;
    .col1, .col2, .col3 {
      width: 100%;
      margin-top: 0!important;
      padding:0!important;
    }
    .col1 {
      padding-bottom: 0;
    }
    .col2 {
      padding-top: 5px;
    }
  }
  &.first {
    border-top: 1px solid transparent;
  }
  &.new-group {

  }
  &.existing-group {
    /*
    border-top: 1px solid transparent;
    .col1 {
      visibility: hidden;
    }

     */
  }
}

.border-hover {
  @include transition-all();
  border-radius: 6px;
  transform: scale(0.95);
  aspect-ratio: 1;
  .bgimg {
    opacity: 0.3;
    border-radius: 6px!important;
    @include transition-all();
    width: calc(100% - 12px);
    height: calc(100% - 12px);
    left:6px;
    top:6px;
  }
  @include transition-all();
  //filter: drop-shadow(0px 0px 4px rgba(0,0,0,0));
  &:hover {
    transform: scale(1);
    .bgimg {
      opacity: 1;
    }
    background-color: rgba(39, 39, 39, 1);
    //filter: drop-shadow(0px 0px 4px rgba(0,0,0,0.45));
  }
}
.border-hover-active {
  transform: scale(1);
  background-color: rgba(39, 39, 39, 1);
  //filter: drop-shadow(0px 0px 4px rgba(0,0,0,0.45));
  .bgimg {
    opacity: 1;
  }
}

.filter-holder {
  .show-mobile.block {
    margin: 20px 0;
    .combobox {

    }
  }
}

.jump {
  cursor: pointer;
  @include transition-all();
  color:$color-pink;
  @include font-montserrat-semibold();
  &:hover {
    color:$color-red;
  }
}

.also-in-hover {
  @include transition-all();
  * {
    @include transition-all();
  }
  svg {
    path {
      @include transition-all();
    }
  }
  &:hover {
    color:#fff!important;
    * {
      color:#fff!important;
    }
    svg {
      path {
        fill: #fff!important;
      }
    }
  }
}


.website-social-bar {
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  .bar {
    border-left: 1px solid #B5B5B5;
    margin-left: 10px;
    padding-left: 10px;
    &:first-child {
      border-left: none;
      margin-left: 0;
      padding-left: 0;
    }

    .social-icons {
      .icon {
        a {
          svg {
            path {
              fill: $color-pink;
            }
          }
          &:hover {
            svg {
              path {
                fill: $color-red;
              }
            }
          }
        }

      }
    }
  }
}

.simple-underline-on-hover {
  @include transition-all();
  border-bottom:1px solid transparent;

  &:hover {
    //text-decoration: underline;
    border-bottom:1px solid $color-red;
  }
  &.dark {
    &:hover {
      //text-decoration: underline;
      border-bottom:1px solid $color-dark;
    }
  }

}


.content-block {
  width: 100%;
  margin-bottom: 40px;
  &:last-child {
    margin-bottom: 0;
  }
  .bgimg-outer {

  }
  .quick-one-swipe {
    width: 100%;
    aspect-ratio: 1.9;
    //height: auto;
    .swiper-slide {
      .caption {
        display: none;
      }
    }
  }
  #quick-one-captions {
    margin-top: 5px;
    display: block;
    font-size: 12px;
    color:#000;
    * {
      font-size: 12px;
    }
  }
}

