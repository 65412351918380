
//LEAVE AT BOTTOM - desktop, mobile global hiders
.show-desktop {
  display: inline-block;
  &.block {
    display: block;
  }
  @media only screen and (max-width: $breakpoint-small) {
    display: none;
    &.block {
      display: none;
    }
  }
}
.show-mobile {
  display: none;
  &.block {
    display: none;
  }
  @media only screen and (max-width: $breakpoint-small) {
    display: inline-block;
    &.block {
      display: block;
    }
  }
}


[data-tooltip].wrap:before {
  @include widthMaxMin(250px);
  white-space: pre-wrap;
  text-align: center;
}


.cc-banner {
  .cc-message {
    padding-left: 30px;
  }
}