
.header-alert {
  background-color: $color-pink;
  padding:10px 0;
  text-align: center;
  z-index: 100001;
  @media only screen and (max-width: $breakpoint-small) {
    .debugger {
      display: none;
    }
  }
  .rte {
    * {
      color:#fff;
      margin:0;
    }
    a {
      text-decoration: underline;
    }
    p {
      padding: 5px 0;
    }
  }

}

.header-strip {
  position: -webkit-sticky;
  position: sticky;
  top:0;

  z-index: 100001;
  background-color: $color-dark;
  border-bottom: 1px $color-gray-dark solid;
  .lr {
    display: flex;
    justify-content: space-between;
    align-items: stretch;

    .bar {
      display: inline-flex;
      align-items: stretch;
      //padding:10px 20px 10px 0;
      border-right:1px $color-gray-dark solid;
      margin-right: 10px;
      &:last-child {
        border-right: 0;
      }
    }

    $break1:1350px;
    $break2:1270px;

    .l {
      width: 45%;
      display: flex;
      align-items: stretch;
      .bar-language {
        .dropdown {

          @media only screen and (max-width: $break1) {
            .dropdown-inner {
             min-width: 180px;
            }
          }
        }
      }

    }
    .r {
      display: flex;
      align-items: stretch;
      width: 55%;
      .bar {
        &:last-child {
          margin-right: 0;
        }
      }
    }
    @media only screen and (max-width: $break1) {
      .l {
        width: 40%;
      }
      .r {
        width: 60%;
      }
    }
    @media only screen and (max-width: $break2) {
      .l {
        width: 37%;
      }
      .r {
        width: 63%;
      }
    }

  }
} //headerr-strip

header.header {
  background-color: $color-dark;
  top:0;
  left:0;
  width:100%;
  position: relative;
  @include transition-all();
  z-index: 10000;


  .header-logo {
    background-color: $color-dark;
    border-bottom: 1px $color-gray-dark solid;
    .lr {
      display: flex;
      align-items: center;
      justify-content: space-between;
      max-width: 712px;
      margin: 0 auto;
      padding: 30px 0;
      width: 100%;
      .l {
        width: 50%;
        top:0;
        img {
          width: 100%;
          height: auto;
        }
      }
      .r {
        width: calc(50% - 50px);
        top:5px;
        * {
          color: $color-gray;
          @include font-montserrat-medium();
          line-height: 1.2em;
          font-size: 14px;
        }
      }
    }
  } //header-strip

  .header-shows {

    background-color: $color-dark;
    border-bottom: 1px $color-gray-dark solid;
    .bars {
      display: flex;
      align-items: center;
      justify-content: center;

      .bar {
        display: inline-flex;
        padding:0 20px;
        button {
          min-width: 180px;
        }
      }

    }
  }

}

.dynamic-nav-bars {
  .link-only {
    .lbl {
      color:#fff;
      @include font-montserrat-semibold();
      font-size: 14px;
    }
  }
  .dropdown .dropdown-inner .selected .lbl {
    color:#fff;
  }
  .dropdown.open .dropdown-inner .selected .lbl {
    color:#222;
  }
  .lbl {
    color:#222;
  }
  svg {
    path {
      fill: #222;
    }
  }
}
